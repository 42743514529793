import React from "react";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import { Box, Paper, Typography, Grid, makeStyles } from "@material-ui/core";

function Page(props) {
  const classes = style();

  return (
    <Paper className={classes.contenedor}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <VerifiedUser style={{ fontSize: 70 }} color="primary"></VerifiedUser>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">Tenemos problemas con los permisos asignado por favor póngase en contacto con el equipo Cydocs.cl</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

const style = makeStyles((theme) => ({
  contenedor: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
}));

export default Page;